/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

// import AddInstructorForm from "./addition-users-forms/add-instructors-form/AddInstructorForm";
// import AddAssistantForm from "./addition-users-forms/add-assistant-form/AddAssistantForm";
// import NewQuizeForm from "../../global-compnents/new-quize-form/NewQuizeForm";
// // import NewQuestionForm from "../../global-compnents/NewQuestionForm";
// import NewRoleForm from "../../custom-pages/role-forms/NewRoleForm";
// import NewCouponeForm from "../../custom-pages/couponse-page/couponse-forms/NewCouponForm";
// import NewPaymentForm from "../../custom-pages/payments-page/payments-forms/NewPaymentForm";
import checkUserRole from "../../global-functions/checkUserRole";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import { useTranslation } from "react-i18next";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul  className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const AdminLeftSideBar = () => {
  const {t} = useTranslation()
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let deshBoard = [
      "",
      "dashboard-dark",
      "schedule",
      "message",
      "activity",
      //"profile",
      "task",
    ],
    courses = [
      "courses",
    ],
    users = ["users-dashbord", "users", "students", "add-user" , "stuff" , "instructors"],
    Contacts = ["Users Messages"],
    Settings = ["contacts-settings" ,  "ui-settings" , "update-ui-settings" , "language"],
    earnings = ["monthly-earnings", "teachers-monthly-sales"],
    categories = ["categories"],
    roles = ["roles" , "permissions"],
    attacheds = ["attacheds-page"],
    coupons = ["coupons"],
    payments = ["payments"]

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          {/* for manager */}
            <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className="" to="/">
                <i className="bi bi-grid"></i>
                <span className="nav-text">{t("Dashboard")}</span>
              </Link>
              {/* <ul className="z-[99]">
                <li>
                  <Link
                    className={`${path === "dashboard" ? "mm-active" : ""}`}
                    to="/"
                  >
                    {" "}
                    {t("Dashboard")}
                  </Link>
                </li>
              </ul> */}
            </li>
          {/* for eveyone now */}
          {
            checkUserRole
            (
              ["manager" , "teacher"]
              ,
              <>
                <li className={`${courses.includes(path) ? "mm-active" : ""}`}>
                  <Link className="" to="/courses">
                    <i className="bi bi-book"></i>
                    <span className="nav-text">{t("Courses")}</span>
                  </Link>
                </li>
              </>
            )
          }
          {
            checkUserRole
            (
              ["manager"]
              ,
              <li className={`${users.includes(path) ? "mm-active" : ""}`}>
                <Link className="has-arrow ai-icon" to="#">
                  <i className="fa-regular fa-user"></i>
                  <span className="nav-text forms">{t("Users")}</span>
                </Link>
                <ul className="z-[99]">   
                {
                  checkUserRole
                  (
                    ["manager"]
                    ,
                    <li>
                      <Link
                        className={`${path === "stuff" ? "mm-active" : ""}`}
                        to="/stuff"
                      >
                        {t("Stuff")}
                      </Link>
                    </li>
                  )
                }
                {
                  checkUserRole
                  (
                    ["manager"]
                    ,
                    <li>
                      <Link
                        className={`${path === "users" ? "mm-active" : ""}`}
                        to="/instructors"
                      >
                        {t("Instructors")}
                      </Link>
                    </li>
                  )
                }
                  <li>
                    <Link
                      className={`${
                        path === "students" ? "mm-active" : ""
                      }`}
                      to="/students"
                    >
                      {t("Students")}
                    </Link>
                  </li>
                </ul>
              </li>
            )
          }
          {
                                
            checkUserRole
            (
              "manager"
              ,
              <li className={`${Contacts.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="/subscriptions">
                <i className="fa-solid fa-dollar-sign"></i>
                  <span className="nav-text forms">{t("Subscriptions")}</span>
                </Link>
              </li>
            )
          }
          {/* for manager*/}
          {
            checkUserRole
            (
              ["manager"]
              ,
              <li className={`${roles.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="/roles">
                  <i className="fa-solid fa-circle-check"></i>
                  <span className="nav-text forms">{t("Roles")}</span>
                </Link>
              </li>
            )
          }
          {/* {
            checkUserRole
            (
              ["manager"]
              ,
              <li className={`${attacheds.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="/attacheds-page">
                  <i className="fa-regular fa-file"></i>
                  <span className="nav-text forms">{t("Attacheds")}</span>
                </Link>
              </li>
            )
          } */}
          {
            checkPermissions
            (
              "coupons_read"
              ,
              <li className={`${coupons.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="/coupons">
                  <i className="fa-solid fa-handshake"></i>
                  <span className="nav-text forms">{t("Coupons")}</span>
                </Link>
              </li>
            )
          }
          {/* for manager */}
          {
            checkUserRole
            (
              ["manager"]
              ,
              <li className={`${categories.includes(path) ? "mm-active" : ""}`}>
                <Link className="ai-icon" to="/categories">
                  <i className="fa-brands fa-windows"></i>
                  <span className="nav-text forms">{t("Categories")}</span>
                </Link>
              </li>
            )
          }
          {/* for has permission read */}
          {
            checkPermissions
            (
              "contacts_read"
              ,
              <li className={`${Contacts.includes(path) ? "mm-active" : ""}`}>
                <Link className=" ai-icon" to="/users-messages">
                  <i className="fa-regular fa-bell"></i>
                  <span className="nav-text forms">{t("Users Messages")}</span>
                </Link>
                {/* <ul className="z-[99]">   
                <li>
                    <Link
                      className={`${path === "Messages" ? "mm-active" : ""}`}
                      to="/users-messages"
                    >
                      {t("Users Messages")}
                    </Link>
                  </li>
                </ul> */}
              </li>
            )
          }

          {/* for manager */}
          {
            checkUserRole
            (
              ["manager"]
              ,
              <>
                <li className={`${earnings.includes(path) ? "mm-active" : ""}`}>
                  <Link className="has-arrow ai-icon" to="#">
                    <i className="fa-solid fa-pen-to-square"></i>
                    <span className="nav-text forms">{t("Reports")}</span>
                  </Link>
                  <ul className="z-[99]">   
                    <li>
                      <Link
                        className={`${path === "/monthly-earnings" ? "mm-active" : ""}`}
                        to="/monthly-earnings"
                      >
                        {t("Subscriptions Reports")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${path === "/teachers-monthly-sales" ? "mm-active" : ""}`}
                        to="/teachers-monthly-sales"
                      >
                        {t("Teachers Reports")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        className={`${path === "contacts-settings" ? "mm-active" : ""}`}
                        to="/contacts-settings"
                      >
                        {t("Contacts Settings")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${path === "update-ui-settings" ? "mm-active" : ""}`}
                        to="/all-sliders"
                      >
                        {t("Sliders Settings")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${path === "lang-settings" ? "mm-active" : ""}`}
                        to="/lang-settings"
                      >
                        {t("Language")}
                      </Link>
                    </li> */}
                  </ul>
                </li>
              </>
            )
          }
          {
            checkUserRole
            (
              ["manager"]
              ,
              <>
                <li className={`${payments.includes(path) ? "mm-active" : ""}`}>
                  <Link className="ai-icon" to="/payments">
                    <i className="fa-solid fa-credit-card"></i>
                    <span className="nav-text forms">{t("Payments")}</span>
                  </Link>
                </li>
                <li className={`${Settings.includes(path) ? "mm-active" : ""}`}>
                  <Link className="has-arrow ai-icon" to="#">
                    <i className="fa-solid fa-pen-to-square"></i>
                    <span className="nav-text forms">{t("Settings")}</span>
                  </Link>
                  <ul className="z-[99]">   
                    <li>
                      <Link
                        className={`${path === "/basic-settings" ? "mm-active" : ""}`}
                        to="/basic-settings"
                      >
                        {t("Basic")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${path === "contacts-settings" ? "mm-active" : ""}`}
                        to="/contacts-settings"
                      >
                        {t("Contacts Settings")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${path === "update-ui-settings" ? "mm-active" : ""}`}
                        to="/all-sliders"
                      >
                        {t("Sliders Settings")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${path === "lang-settings" ? "mm-active" : ""}`}
                        to="/lang-settings"
                      >
                        {t("Language")}
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            )
          }
        </MM>

        <div className="copyright">
          {/* <p><strong>GetSkills Online Learning Admin</strong> © 2022 All Rights Reserved</p> */}
          <p className="fs-12">{/* <span className="heart"></span>  */}</p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default AdminLeftSideBar;