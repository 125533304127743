// import "./chapter.css";
import { useEffect, useRef, useState , useContext } from "react";
import { Link } from "react-router-dom";
import checkPermissions from "../../../../global-functions/checkPermissionsFunc";
import { useDispatch, useSelector } from "react-redux";
import {useParams} from "react-router-dom";
import ContextApi from "../../../../../store/ContextApi"
import { Spinner } from "react-bootstrap";
import { getLessonRequirmentsFunc } from "../../../../../store/custom-actions/lessons-actions/lessons-requirments-actions/GetLessonRequirmentsSlicer";
import swal from "sweetalert";
import { deleteLessonRequirmentFunc } from "../../../../../store/custom-actions/lessons-actions/lessons-requirments-actions/DeleteLessonRequirmentSlicer";
import OverLayLoading from "../../../custome-overlay-loading/OverLayLoading";
import UpdateRequirmentForm from "../../../../global-compnents/lesson-box-forms/UpdateRequirementForm";
import PassingLessonRequireForm from "../../../../global-compnents/lesson-box-forms/PassingLessonRequireForm";
import { ShowLessonFunc } from "../../../../../store/custom-actions/lessons-actions/ShowLessonSlicer";
import { getSingleChapterFunc } from "../../../../../store/custom-actions/chapters/GetSingleChapterSlicer";
import PageTitle from "../../../../layouts/PageTitle";
import { useTranslation } from "react-i18next";

export default function LessonRequirments() {
    const {t} = useTranslation();
    const {lessonId} = useParams();
    const contextApi = useContext(ContextApi);
    const dispatch = useDispatch();
    const [lessonsList, setLessonsList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [filteredRequirments, setFilteredRequirments] = useState([]);
    const requirmentsApi = `${contextApi.dashboard_URL_API}/lessons/${lessonId}/lesson-requirements`;
    const deleteRequirmentsApi = `${contextApi.dashboard_URL_API}/lesson-requirements`;
    const singleLessonApi = `${contextApi.dashboard_URL_API}/lessons/${lessonId}`;
    const { loading: getRequirmentsLoading, data: requiremnstData } = useSelector(
        state => state.getLessonRequirmentsSlicer
    );
    const {loading:deleteRequirmentLoading} = useSelector(
        state => state.deleteLessonRequirmentSlicer
    );
    const {loading:getChapterLoading , data:chapterData} = useSelector(
        state => state.getSingleChapterSlicer
    )
    const {loading:getSingleLessonDataLoading , data:singleLessonData} = useSelector(
        state => state.showLessonSlicer
    )
    const [requirmentsList , setRequirmentsList] = useState([]);
    const [showUpdateRequirmentForm , setShowUpdateRequirmentForm] = useState();
    const [oldRequireLessonId , setOldRequireLessonId] = useState();
    const [oldRequireLessonName , setOldRequireLessonName] = useState();
    const [oldMinGradePass , setOldMinGradePass] = useState();
    const [requirmentWillUpdate , setRequirmentWillUpdate] = useState();
    const [showNewRequirmentsForm , setShowNewRequirmentsForm] = useState();
    const [courseLessonsList , setCourseLessonsList] = useState([]);
    const [chapterId , setChapterId] = useState(null);
    const [chapterLessons , setChapterLessons] = useState([]);
    const [singleLessonName , setSingleLessonName] = useState("Lesson");
    const [oldRequireLesson , setOldRequireLesson] = useState();
    const getSingleLesson = () => {
        dispatch(ShowLessonFunc(singleLessonApi))
    }
    const getChapterData = () => {
        const chapterApi = `${contextApi.dashboard_URL_API}/chapters/${chapterId}`;
        dispatch(getSingleChapterFunc(chapterApi))
    }
    useEffect(() => {
        const getData = () => {
            dispatch(getLessonRequirmentsFunc(requirmentsApi))
        }
        getData();
    },[])
    useEffect(() => {
        if(requiremnstData) {
            setRequirmentsList(requiremnstData);
            // requiremnstData[0]?.lesson?.name && setLessonName(requiremnstData[0].lesson.name) 
            getSingleLesson();
        }
    },[requiremnstData])
    useEffect(() => {
        if(singleLessonData) {
            setChapterId(singleLessonData.chapter_id);
            setSingleLessonName(singleLessonData.name)
        }
    },[singleLessonData])
    useEffect(() => {
        if(chapterId) {
            getChapterData();
        }
    },[chapterId])
    useEffect(() => {
        if(chapterData) {
            setChapterLessons(chapterData.lessons);
        }
    },[chapterData])
    const sort = 20;
    const activePag = useRef(0);

    useEffect(() => {
        if (requiremnstData) {
            setLessonsList(requiremnstData);
            setFilteredRequirments(requiremnstData.slice(
                activePag.current * sort,
                (activePag.current + 1) * sort
            ));
        }
    }, [requiremnstData]);

    const onClick = (i) => {
        activePag.current = i;
        setFilteredRequirments(lessonsList.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
        ));
    };
        let paggination = Array(Math.ceil(lessonsList.length / sort))
      .fill()
      .map((_, i) => i + 1);

    useEffect(() => {
        if (searchValue !== "") {
            const searchResult = requiremnstData &&
                requiremnstData.length >= 1 && requiremnstData.filter((lesson) =>
                    lesson.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                );
            setFilteredRequirments(searchResult);
        } else {
            setFilteredRequirments(requiremnstData.slice(
                activePag.current * sort,
                (activePag.current + 1) * sort
            ));
        }
    }, [searchValue, requiremnstData]);

    const deleteRequirment = async (requirmentId) => {
        const deleteFunc = () => {
            dispatch(deleteLessonRequirmentFunc(`${deleteRequirmentsApi}/${requirmentId}`))
            .then(result => {
                result.payload.success === true && dispatch(getLessonRequirmentsFunc(requirmentsApi));
            })
        }
        // Swal.fire({
        //     title: "Confirm Requirment Deletion",
        //     text: `Once deleted, this will be not avilable!`,
        //     icon: "warning",
        //     buttons: true,
        //     dangerMode: true,
        //   }).then((willDelete) => {
        //     if (willDelete) {
        //       deleteFunc()
        //     }
        //   });
        swal({
            title: t("Confirm Item Deletion"),
            text: t(`Once deleted, this will not avilable in platform!`),
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              deleteFunc()
            }
          })
    }
    return (
        <>
            {
                (!getRequirmentsLoading && !getSingleLessonDataLoading)
                &&
                <PageTitle motherMenu={"Course"} activeMenu={singleLessonName} thirdActiveMenu={"Requirments"} />
            }
            {
                (getRequirmentsLoading || getSingleLessonDataLoading)
                ?
                <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                </div>
                :
                (
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header flex justify-between items-center">
                                <h4 className="card-title">{t("Requirments")}</h4>
                                <div className="relative add-and-search flex items-center">
                                    {/* <div className="input-group search-area w-auto mr-2">
                                        <span className="input-group-text">
                                            <Link to={"#"}>
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 32 32"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                                        fill="var(--primary)"
                                                    ></path>
                                                </svg>
                                            </Link>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search here..."
                                            value={searchValue}
                                            onChange={(e) => setSearchValue(e.target.value)}
                                        />
                                    </div> */}
                                    {checkPermissions(
                                        "create lessons",
                                        <Link
                                            to={`#`}
                                            className="btn btn-primary btn-sm"
                                            onClick={() => setShowNewRequirmentsForm(true)}
                                        >
                                            {t("Create")}
                                            <i className="fa-solid fa-plus ml-1"></i>
                                        </Link>
                                    )}
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="w-100 table-responsive lessons-table">
                                    <div id="example_wrapper" className="dataTables_wrapper">
                                        <table id="example" className="display w-100 dataTable">

                                            <thead>
                                                <tr>
                                                <th className="">#{t("ID")}</th>
                                                <th className="">{t("Min Score")}</th>
                                                <th className="">{t("Creation")}</th>
                                                <th className="">{t("Last Update")}</th>
                                                <th className="">{t("Require Lesson")}</th>
                                                <th className=" text-end">
                                                    {t("Decision")}
                                                </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    filteredRequirments && filteredRequirments.length >= 1
                                                    ?
                                                    filteredRequirments.map((requirment, index) => {
                                                        const {required_lesson} = requirment;
                                                        const {
                                                            name:requireLessonName , 
                                                            type:requireLessonType , 
                                                            id:requireLessonId ,
                                                        } = required_lesson;
                                                        const creationDate = new Date(requirment.created_at)
                                                        const creationYear = creationDate.getFullYear();
                                                        const creationMonth = creationDate.getMonth()+1;
                                                        const creationDay = creationDate.getDate();
                                                        const latestUpdateDate = new Date(requirment.updated_at)
                                                        const latestUpdateYear = latestUpdateDate.getFullYear();
                                                        const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                                                        const latestUpdateDay = creationDate.getDate();
                                                        return (
                                                        <tr key={index}>
                                                            <td className="font-bold">
                                                            #{requirment.id}
                                                            </td>
                                                            <td className="">
                                                                {
                                                                requirment.min_passing_grade 
                                                                ?
                                                                requirment.min_passing_grade
                                                                :
                                                                "-"
                                                                }
                                                            </td>
                                                            <td>
                                                            {`${creationYear} / ${creationMonth} / ${creationDay}`}
                                                            </td>
                                                            <td className="">
                                                            {`${latestUpdateYear} / ${latestUpdateMonth} / ${latestUpdateDay}`}
                                                            </td>
                                                            <td className="">
                                                                <h5 className="m-0 flex items-center">
                                                                    {/* {checkLessonType(requireLessonType)} */}
                                                                    {requireLessonName}
                                                                </h5>
                                                            </td>
                                                            <td className="">
                                                            <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                                                onClick={() => {
                                                                    deleteRequirment(requirment.id)
                                                                }}
                                                            >
                                                                <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                                                >
                                                                    <i className="fa-solid fa-trash text-white"></i>
                                                                    <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                                                    >Delete</span>
                                                                </div>
                                                            </button>
                                                            <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                                                onClick={() => {
                                                                    setOldRequireLesson(required_lesson)
                                                                    setOldRequireLessonName(requireLessonName);
                                                                    setOldRequireLessonId(requireLessonId);
                                                                    setOldMinGradePass(requirment.min_passing_grade);
                                                                    setRequirmentWillUpdate(requirment.id);
                                                                    setShowUpdateRequirmentForm(true);
                                                                }}
                                                                >
                                                                <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                                                >
                                                                    <i className="fa-solid fa-pen-clip text-white"></i>
                                                                    <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                                                    >{t("Update")}</span>
                                                                </div>
                                                            </button>
                                                            </td>
                                                        </tr>
                                                        );
                                                    })
                                                    :
                                                    (
                                                        searchValue !== "" 
                                                        &&
                                                        <tr>
                                                            <td colSpan={3}>{t("No data available")}</td>
                                                        </tr>
                                                    )
                                                }
                                                {
                                                    searchValue === "" && filteredRequirments && filteredRequirments.length < 1 
                                                    &&
                                                    <tr>
                                                        <td colSpan={3}>{t("No data available")}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                            <div className="dataTables_info">
                                                {t("Showing")} {activePag.current * sort + 1} to{" "}
                                                {lessonsList.length > (activePag.current + 1) * sort
                                                    ? (activePag.current + 1) * sort
                                                    : lessonsList.length}{" "}
                                                {t("of")} {lessonsList.length} {t("entries")}
                                            </div>
                                            <div
                                                className="dataTables_paginate paging_simple_numbers mb-0"
                                                id="example5_paginate"
                                            >
                                                <Link
                                                    className="paginate_button previous disabled"
                                                    to="#"
                                                    onClick={() =>
                                                        activePag.current > 0 && onClick(activePag.current - 1)
                                                    }
                                                >
                                                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                </Link>
                                                <span>
                                                    {paggination.map((number, i) => (
                                                        <Link
                                                            key={i}
                                                            to="#"
                                                            className={`paginate_button  ${activePag.current === i ? "current" : ""
                                                                } `}
                                                            onClick={() => onClick(i)}
                                                        >
                                                            {number}
                                                        </Link>
                                                    ))}
                                                </span>
                                                <Link
                                                    className="paginate_button next"
                                                    to="#"
                                                    onClick={() =>
                                                        activePag.current + 1 < paggination.length &&
                                                        onClick(activePag.current + 1)
                                                    }
                                                >
                                                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        <UpdateRequirmentForm
            show = {showUpdateRequirmentForm}
            onHide = {() => setShowUpdateRequirmentForm(false)}
            old_require_lesson_id = {oldRequireLessonId}
            old_require_lesson_name = {oldRequireLessonName}
            old_min_grade_pass = {oldMinGradePass}
            lesson_requirement_id = {requirmentWillUpdate}
            lessons = {chapterLessons}
            reftchData = {() => dispatch(getLessonRequirmentsFunc(requirmentsApi))}
            old_require_lesson = {oldRequireLesson}
        />
        <PassingLessonRequireForm
            show = {showNewRequirmentsForm}
            onHide = {()=>setShowNewRequirmentsForm(false)}
            lesson_id = {lessonId}
            lesson_requirement_id = {requirmentWillUpdate}
            lessons = {chapterLessons}
            reftchData = {() => dispatch(getLessonRequirmentsFunc(requirmentsApi))}
            
        />
            <OverLayLoading loading={deleteRequirmentLoading} loadingText={"Loading.."}/>
        </>
    );
}
