import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import { useContext, useEffect, useRef, useState } from "react";
import ContextApi from "../../../store/ContextApi";
import { fetchAllCategoriesFunc } from "../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer";
import { deleteCategoryFunc } from "../../../store/custom-actions/categories-actions/DeleteCategoriesSlicer";
import swal from "sweetalert";
import AddCategoryForm from "./AddCategoryForm";
import UpdateCategoryForm from "./UpdateCategoryForm";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import { Link } from "react-router-dom";
import PageTitle from "../../layouts/PageTitle";
import { useTranslation } from "react-i18next";
import CategoryCard from "./single-category/CategoryCard";

function CategoriesPage() {
    const {t} = useTranslation();
    const {loading , categoriesData} = useSelector(state => state.getAllCategoriesSlicer)
    const {deleteCategoriesLoading} = useSelector(state => state.deleteCategorySlicer)
    const contextApi = useContext(ContextApi);
    const categories_API_URL = `${contextApi.dashboard_URL_API}/categories?type=only_parent`;
    const [categoriesList , setCategoriesList] = useState([]);
    const [showCategoryForm , setShowCategoryForm] = useState();
    const [showUpdateCategoryForm , setShowUpdateCategoryForm] = useState();
    const [catNameWillUpdate , setCatNameWillUpdate] = useState();
    const [catChidernWillUpdate , setCatChidernWillUpdate] = useState();
    const [catIdWillUpdate , setCatIdWillUpdate] = useState();
    const [catParnetIdWillUpdate , setCatParnetIdWillUpdate] = useState();
    const [catWillUpdateImage , setCatWillUpdateImage] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        const getCategoriesData = async () => {
          try {
            dispatch(fetchAllCategoriesFunc(categories_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getCategoriesData();
      }, []);
      useEffect(() => {
        categoriesData && setCategoriesList(categoriesData);
      }, [categoriesData]);
      useEffect(() => {
        categoriesData && setCategoriesList(categoriesData);
        categoriesData && (jobData.current = categoriesData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ));
      }, [categoriesData]);
      const sort = 20;
      let paggination = Array(Math.ceil(categoriesList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        categoriesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = categoriesList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
      const deleteCategoryFunction = async (catId) => {
        const deleteFunc = () => {
          try {
            dispatch(deleteCategoryFunc(`${categories_API_URL}/${catId}`))
            .then((result)=>{
              if(result.payload.success === true){
                dispatch(fetchAllCategoriesFunc(categories_API_URL));
              }
            });
          }catch (error) {
            console.log(error);
          }
        }
        swal({
          title: t("Confirm Item Deletion"),
          text: t(`Once deleted, this category will not be category in platform!`),
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            deleteFunc()
          }
        })
      };
      const [searchValue , setSearchValue] = useState("")
      const search = () => {
        if(searchValue !== "") {
          const searchResult = categoriesData &&
          categoriesData.length >= 1 && categoriesData.filter((cat) =>  
            cat.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
        );
          jobData.current = searchResult
        }else {
          dispatch(fetchAllCategoriesFunc(categories_API_URL))
        }
      }
      useEffect(() => {
        searchValue === "" && dispatch(fetchAllCategoriesFunc(categories_API_URL));
      },[searchValue])

    return (
        <>
        <PageTitle activeMenu={"Categories"} motherMenu={"Dashboard"}/>
        {
          categoriesData && categoriesList?.length < 1
          &&
          <div className="relative flex justify-end items-center">
            <Link 
            to={"#"} 
            className="btn btn-primary btn-sm"
            onClick={() => {
              setShowCategoryForm(true)
            }}
            >
              {t("Create")}
              <i className="fa-solid fa-plus ml-1"></i>
            </Link>
          </div>
        }
        <div className="categories-section relative ">
            {
                loading 
                ?
                (
                    <>
                    <div className="row d-flex justify-content-center gap-2">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                    </div>
                  </>
                )
                :
                <>
                    {
                        categoriesList && categoriesList.length > 0 
                        ?
                        <div className="col-12">
                          <div className="card">
                            <div className="card-header flex justify-between items-center">
                              <h4 className="card-title">{t("Categories")}</h4>
                              <div className="relative add-and-search flex items-center">
                                <div className="input-group search-area w-auto mr-2">
                                    <span className="input-group-text"
                                      onClick={() => search()}
                                    >
                                        <Link to={"#"}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                            d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                            fill="var(--primary)"
                                            ></path>
                                        </svg>
                                        </Link>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder={t("Search here...")}
                                        onChange={(e) => {
                                          setSearchValue(e.target.value);
                                        }}
                                    />
                                </div>
                                {
                                    <Link 
                                    to={"#"} 
                                    className="btn btn-primary btn-sm"
                                    onClick={() => {
                                      setShowCategoryForm(true)
                                    }}
                                    >
                                      {t("Create")}
                                      <i className="fa-solid fa-plus ml-1"></i>
                                    </Link>
                                }
                              </div>
                            </div>
                            {/* <div className="card-body">
                              <div className="w-100 table-responsive">
                                <div id="example_wrapper" className="dataTables_wrapper">
                                  <table id="example" className="display w-100 dataTable">
                                    <thead>
                                      <tr role="row">
                                        <th>#{t("ID")}</th>
                                        <th>{t("Name")}</th>
                                        <th>{t("Creation Date")}</th>
                                        <th>{t("Action")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        jobData.current.map((item , index) => {
                                            const date = new Date(item.created_at)
                                            const year = date.getFullYear();
                                            const month = date.getMonth()+1;
                                            const day = date.getDate();
                                            return (
                                            <tr key={index}>
                                              <td>
                                              <strong>#{index+1}</strong>
                                              </td>
                                              <td>
                                                {item.name}
                                              </td>
                                              <td>{year} / {month} / {day}</td>
                                              <td className="flex items-center">
                                              <Link
                                                    to="#"
                                                    className="btn btn-danger shadow btn-xs sharp"
                                                    onClick={() => {
                                                        deleteCategoryFunction(item?.id);
                                                    }}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </Link>
                                                <Link
                                                    to={`#`}
                                                    className="btn btn-primary shadow btn-xs sharp me-1 ml-1"
                                                    onClick={() => {
                                                      setCatNameWillUpdate(item.name)
                                                      setCatChidernWillUpdate(item.all_children)
                                                      setCatParnetIdWillUpdate(item.parent_id)
                                                      setCatWillUpdateImage(item.image_url)
                                                      setCatIdWillUpdate(item.id)
                                                      setShowUpdateCategoryForm(true);
                                                  }}
                                                >
                                                    <i className="fas fa-pencil-alt"></i>
                                                </Link>
                                                <Link
                                                    to={`/single-category/${item.id}`}
                                                    className="btn btn-primary shadow btn-xs sharp me-1 ml-1"
                                                >
                                                    <i className="fa-solid fa-eye"></i>
                                                </Link>
                                              </td>
                                            </tr>
                                            )
                                        }) 
                                      }
                                    </tbody>
                                  </table>
                                  <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                    <div className="dataTables_info">
                                      {t("Showing")} {activePag.current * sort + 1} to{" "}
                                      {categoriesList.length > (activePag.current + 1) * sort
                                        ? (activePag.current + 1) * sort
                                        : categoriesList.length}{" "}
                                      {t("of")} {categoriesList.length} {t("entries")}
                                    </div>
                                    <div
                                      className="dataTables_paginate paging_simple_numbers mb-0"
                                      id="example5_paginate"
                                    >
                                      <Link
                                        className="paginate_button previous disabled"
                                      
                                        to="#"
                                        onClick={() =>
                                          activePag.current > 0 && onClick(activePag.current - 1)
                                        }
                                      >
                                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                      </Link>
                                      <span>
                                        {paggination.map((number, i) => (
                                          <Link
                                            key={i}
                                          
                                            to="#"
                                            className={`paginate_button  ${
                                              activePag.current === i ? "current" : ""
                                            } `}
                                            onClick={() => onClick(i)}
                                          >
                                            {number}
                                          </Link>
                                        ))}
                                      </span>
                                      <Link
                                        className="paginate_button next"
                                      
                                        to="#"
                                        onClick={() =>
                                          activePag.current + 1 < paggination.length &&
                                          onClick(activePag.current + 1)
                                        }
                                      >
                                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>
                          <div className="row">
                            {
                              categoriesList?.length >= 1
                              ?
                              categoriesList?.map((cat , index) => {
                                const {id , all_children , name , image_url} = cat;
                                // const {name:courseName} = course;
                                return (
                                    <CategoryCard
                                    id={id}
                                    name={name}
                                    key={index}
                                    image={image_url}
                                    childrenCount={all_children.length}
                                    refetchData = {() => dispatch(fetchAllCategoriesFunc(categories_API_URL))}
                                    />
                                )
                              })
                              :
                              <h3>{t("No Data Avilable")}</h3>
                            }
                          </div>
                        </div>
                        :
                        <h2>{t("No data available")}</h2>
                    }
                </>
            }
        </div>
        <AddCategoryForm
          showCategoryForm = {showCategoryForm} 
          hideCategoryForm = {()=>setShowCategoryForm(false)}
          categoriesApi = {categories_API_URL}
          categoriesList={categoriesList}
          />
        <UpdateCategoryForm
          showUpdateCategoryForm = {showUpdateCategoryForm} 
          hideUpdateCategoryForm = {()=>setShowUpdateCategoryForm(false)}
          categoriesApi = {categories_API_URL}
          categoryName={catNameWillUpdate}
          categoryId={catIdWillUpdate}
          categoriesList={categoriesList}
          // catChidernWillUpdate = {catChidernWillUpdate}
          categoryParentId = {catParnetIdWillUpdate}
          categoryImage = {catWillUpdateImage}
        />
        <OverLayLoading loading={deleteCategoriesLoading} loadingText={"Deleting Category..."}/>
        </>
    )
}


export default CategoriesPage