import { useState , useEffect, useContext, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import faildImg from "../../../media/images/planet.png"
import ContextApi from "../../../store/ContextApi";
import { deleteRoleFunc } from "../../../store/custom-actions/roles-actions/DeleteRoleSlicer";
import { getRolesFunc } from "../../../store/custom-actions/roles-actions/GetRolesSlicer";
import swal from "sweetalert";
import UpdateRoleForm from "../role-forms/UpdateRoleForm";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import ShowRoleModal from "./RoleModal";
import { Link } from "react-router-dom";
import NewRoleForm from "../role-forms/NewRoleForm";
import { useTranslation } from "react-i18next";

function RolesPage() {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const roles_API_URL = `${contextApi.dashboard_URL_API}/roles`;
    const [rolesList , setRolesList] = useState([]);
    const dispatch = useDispatch();
    const {loading:getRolesLoading , data:rolesData} = useSelector(
        state => state.getRolesSlicer
    )
    const {loading:deleteRoleLoading} = useSelector(
        state => state.deleteRoleSlicer
    )
    const [showUpdateRoleForm , setShowUpdateRoleForm] = useState()
    const [roleId , setRoleId] = useState()
    const [role , setRole] = useState()
    const [showRole , setShowRole] = useState();
    const [searchValue , setSearchValue] = useState("");
    const [showNewRoleForm , setShowNewRoleForm] = useState();
    const getRoles = async () => {
        try {
            dispatch(getRolesFunc(roles_API_URL))
            .then(result => {
                console.log(result)
            })
        }
        catch (error){
            alert(error)
        }
    }
    useEffect(() => {
        getRoles()
    },[])
    useEffect(() => {
        rolesData && setRolesList(rolesData)
    },[rolesData])
    const [filtredRoles, setFiltredRoles] = useState([]);
    const sort = 20;
    const activePag = useRef(0);

    useEffect(() => {
        if (rolesData) {
            setRolesList(rolesData);
            setFiltredRoles(rolesData.slice(
                activePag.current * sort,
                (activePag.current + 1) * sort
            ));
        }
    }, [rolesData]);

    const onClick = (i) => {
        activePag.current = i;
        setFiltredRoles(rolesList.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
        ));
    };
        let paggination = Array(Math.ceil(rolesList.length / sort))
      .fill()
      .map((_, i) => i + 1);

    useEffect(() => {
        if (searchValue !== "" && rolesData && rolesData.length >= 1) {
            const searchResult = rolesData &&
                rolesData.length >= 1 && rolesData.filter((role) =>
                    role.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                );
            setFiltredRoles(searchResult);
        } else {
            rolesData && rolesData.length >= 1 && setFiltredRoles(rolesData.slice(
                activePag.current * sort,
                (activePag.current + 1) * sort
            ));
        }
    }, [searchValue, rolesData]);
    const deleteRole = async (roleId) => {
        const deleteFunc = () => {
            try {
                dispatch(deleteRoleFunc(`${roles_API_URL}/${roleId}`))
                .then(result => {
                    getRoles();
                })
            }
            catch (error){
                alert(error)
            }
        }
        swal({
            title: t("Confirm Role Deletion"),
            text: t(`Once deleted, this will be not avilable in platform!`),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteFunc()
            }
        });
    }

    return (
        <>
            <PageTitle activeMenu="Platform Roles" motherMenu="Home" customClasses={"m-0"} />
            <div className="content relative w-full">
                {
                    getRolesLoading
                    ?
                    (
                        <>
                            <div className="row d-flex justify-content-center gap-2">
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                                <Spinner animation="grow" variant="warning" />
                            </div>
                        </>
                    )
                    :
                    (
                        rolesData
                        ?
                        (
                            rolesList && rolesList.length >= 1
                            ?
                            <div className="card mt-3">
                                <div className="card-header flex justify-between items-center">
                                    <h4 className="card-title">{t("Roles")}</h4>
                                    <div className="relative add-and-search flex items-center">
                                        <div className="input-group search-area w-auto mr-2">
                                            <span className="input-group-text">
                                                <Link to={"#"}>
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 32 32"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                                            fill="var(--primary)"
                                                        ></path>
                                                    </svg>
                                                </Link>
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={t("Search here...")}
                                                value={searchValue}
                                                onChange={(e) => setSearchValue(e.target.value)}
                                            />
                                        </div>
                                    
                                        <Link
                                            to={`#`}
                                            className="btn btn-primary btn-sm"
                                            onClick={() => setShowNewRoleForm(true)}
                                        >
                                            {t("Create")}
                                            <i className="fa-solid fa-plus ml-1"></i>
                                        </Link>
                                        
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="w-100 table-responsive lessons-table">
                                        <div id="example_wrapper" className="dataTables_wrapper">
                                            <table id="example" className="display w-100 dataTable">
                                                <thead>
                                                    <tr>
                                                    <th className="">#{t("ID")}</th>
                                                    <th className="">{t("Name")}</th>
                                                    <th className="">{t("display name")}</th>
                                                    <th className="">{t("Creation")}</th>
                                                    <th className="">{t("Last Update")}</th>
                                                    <th className="">
                                                        {t("Decision")}
                                                    </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                            {filtredRoles &&
                                            filtredRoles.length > 0 ?
                                            filtredRoles.map((role, index) => {
                                                const creationDate = new Date(role.created_at)
                                                const creationYear = creationDate.getFullYear();
                                                const creationMonth = creationDate.getMonth()+1;
                                                const creationDay = creationDate.getDate();
                                                const latestUpdateDate = new Date(role.updated_at)
                                                const latestUpdateYear = latestUpdateDate.getFullYear();
                                                const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                                                const latestUpdateDay = creationDate.getDate();
                                                return (
                                                role.display_name.toLowerCase() !== "manager"
                                                &&
                                                <tr key={index}>
                                                    <td className="font-bold">
                                                    #{role.id}
                                                    </td>
                                                    <td className="">
                                                        {role.name}
                                                    </td>
                                                    <td className="">
                                                        {role.display_name}
                                                    </td>
                                                    <td>
                                                    {`${creationYear} / ${creationMonth} / ${creationDay}`}
                                                    </td>
                                                    <td className="">
                                                    {`${latestUpdateYear} / ${latestUpdateMonth} / ${latestUpdateDay}`}
                                                    </td>
                                                    <td className="">
                                                    <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                                        onClick={() => {
                                                            deleteRole(role.id)
                                                        }}
                                                    >
                                                        <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                                        >
                                                            <i className="fa-solid fa-trash text-white"></i>
                                                            <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                                            >{t("Delete")}</span>
                                                        </div>
                                                    </button>
                                                    <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                                        onClick={() => {
                                                            setRoleId(role.id);
                                                            setShowUpdateRoleForm(true);
                                                            setRole(role)
                                                        }}
                                                        >
                                                        <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                                        >
                                                            <i className="fa-solid fa-pen-clip text-white"></i>
                                                            <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                                            >{t("Update")}</span>
                                                        </div>
                                                    </button>
                                                    <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                                        onClick={() => {
                                                            setRoleId(role.id);
                                                            setShowRole(true);
                                                            setRole(role)
                                                        }}
                                                        >
                                                        <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                                        >
                                                            <i className="fa-solid fa-eye text-white"></i>
                                                        </div>
                                                    </button>
                                                    </td>
                                                </tr>
                                                );
                                            })
                                            :
                                            <td colSpan={8} className="flex flex-col justify-center items-center">
                                                {/* <img src={noRequirmentsIcon} alt="no subscription" className="w-[50px] border-none"/> */}
                                                <p className="m-0 border-none">{t("No data available")}</p> 
                                            </td>
                                            }
                                                    {
                                                        searchValue === "" && filtredRoles && filtredRoles.length < 1 
                                                        &&
                                                        <tr>
                                                            <td colSpan={3}>{t("No data available")}</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                                <div className="dataTables_info">
                                                    {t("Showing")} {activePag.current * sort + 1} to{" "}
                                                    {rolesList.length > (activePag.current + 1) * sort
                                                        ? (activePag.current + 1) * sort
                                                        : rolesList.length}{" "}
                                                    {t("of")} {rolesList.length} {t("entries")}
                                                </div>
                                                <div
                                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                                    id="example5_paginate"
                                                >
                                                    <Link
                                                        className="paginate_button previous disabled"
                                                        to="#"
                                                        onClick={() =>
                                                            activePag.current > 0 && onClick(activePag.current - 1)
                                                        }
                                                    >
                                                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                    </Link>
                                                    <span>
                                                        {paggination.map((number, i) => (
                                                            <Link
                                                                key={i}
                                                                to="#"
                                                                className={`paginate_button  ${activePag.current === i ? "current" : ""
                                                                    } `}
                                                                onClick={() => onClick(i)}
                                                            >
                                                                {number}
                                                            </Link>
                                                        ))}
                                                    </span>
                                                    <Link
                                                        className="paginate_button next"
                                                        to="#"
                                                        onClick={() =>
                                                            activePag.current + 1 < paggination.length &&
                                                            onClick(activePag.current + 1)
                                                        }
                                                    >
                                                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            :
                            <h2>{t("No data available")}</h2>
                        )
                        :
                        <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                            <img src={faildImg} alt="faild img" className='w-[150px] mb-3'/>
                            <p>{t("No data available")} 
                            <span className="text-primary cursor-pointer"
                            onClick={()=> getRoles()}
                            >
                            {t("Try again")}
                            </span>
                            </p>
                        </div>
                    )
                }
            </div>
        {
            showUpdateRoleForm
            &&
            <UpdateRoleForm
                show = {showUpdateRoleForm}
                onHide = {() => setShowUpdateRoleForm(false)}
                role_id = {roleId}
                role = {role}
            />
        }
        {
            showRole
            &&
            <ShowRoleModal
                show = {showRole}
                onHide = {() => setShowRole(false)}
                role_id = {roleId}
                role = {role}
            />
        }
        {
            showNewRoleForm
            &&
            <NewRoleForm
            show={showNewRoleForm}
            onHide={()=>setShowNewRoleForm(false)}
            />
        }
        <OverLayLoading loading={deleteRoleLoading} loadingText={"Deleting Role.."} />
        </>
    )
}

export default RolesPage;