import { configureStore } from "@reduxjs/toolkit";
//home
import getHomeDataSlicer from "./custom-actions/home/GetHomeDataSlicer";

// courses slicers
import getAllCoursesSlicer from "./custom-actions/courses-actions/GetAllCoursesSlicer";
import deleteCourseSlicer from "./custom-actions/courses-actions/DeleteCourseSlicer";
import getSingleCourseSlicer from "./custom-actions/courses-actions/GetSingleCourseSlicer";
import addNewCourseSlicer from "./custom-actions/courses-actions/AddCourseSlicer";
import approveCourseSlicer from "./custom-actions/courses-actions/ApproveCouresSlicer";
import editCourseSlicer from "./custom-actions/courses-actions/EditCourseSlicer";
import updateCourseImageSlicer from "./custom-actions/courses-actions/UpdateCourseImageSlicer";
import filterCoursesSlicer from "./custom-actions/courses-actions/FilterCoursesSlicer";
import unApproveCourseSlicer from "./custom-actions/courses-actions/UnApproveCouresSlicer";
import addNewExtintionSlicer from "./custom-actions/courses-actions/excutions-actions/AddCourseExtintionsSlicer";
import updateCourseExecutionSlicer from "./custom-actions/courses-actions/excutions-actions/UpdateCourseExecutionSlicer";
import getCourseExcutionsSlicer from "./custom-actions/courses-actions/excutions-actions/GetCourseExcutionsSlicer";
import deleteCourseExcutionSlicer from "./custom-actions/courses-actions/excutions-actions/DeleteCourseExcutionSlicer";
import getSingleExcutionSlicer from "./custom-actions/courses-actions/excutions-actions/GetSingleExcution";

// students slicers
import getAllStudents from "./custom-actions/students-actions/GetAllStudentsSlicer";
import deleteStudentSlicer from "./custom-actions/students-actions/DeleteStudentSlicer";
import editStudentSlicer from "./custom-actions/students-actions/EditStudentSlicer";
import getSingleStudentSlicer from "./custom-actions/students-actions/GetSingleStudentSlicer";
import addStudentSlicer from "./custom-actions/students-actions/AddStudentSlicer";
import unApproveStudentSlicer from "./custom-actions/students-actions/UnApproveStudentSlicer";
import approveStudentSlicer from "./custom-actions/students-actions/ApproveStudentSlicer";

// categories slicers
import getAllCategoriesSlicer from "./custom-actions/categories-actions/GetAllCategoriesSlicer";
import getSingleCategorySlicer from "./custom-actions/categories-actions/GetSingleCategorySlicer";
import deleteCategorySlicer from "./custom-actions/categories-actions/DeleteCategoriesSlicer";
import addCategorySlicer from "./custom-actions/categories-actions/AddCategorySlicer";
import updateCategorySlicer from "./custom-actions/categories-actions/UpdateCategorySlicer";

// instructors slicers
import fetchUsersSlicer from "./custom-actions/users-actions/GetUsersSlicer";
import editUserSlicer from "./custom-actions/users-actions/EditUserSlicer";
import addNewUserSLicer from "./custom-actions/users-actions/AddUserSlicer";
import getSingleUserSlicer from "./custom-actions/users-actions/GetSingleUserSlicer";
import approveTeacherSlicer from "./custom-actions/users-actions/ApproveTeacherSlicer";
import unApproveTeacherSlicer from "./custom-actions/users-actions/UnApproveTeacherSlicer";
import changeUserPasswordSlicer from "./custom-actions/users-actions/ChangeUserPasswordSlicer";
import filterInstructorsSlicer from "./custom-actions/users-actions/FilterUserSlicer";
import editUserImageSlicer from "./custom-actions/users-actions/UpdateUserImageSlicer"
import deleteUserSlicer from "./custom-actions/users-actions/DeleteUserSlicer"

// quizzes slicers
import getQuizeSingleAttemptsSlicer from "./custom-actions/attempts-actions/GetSingleAttemp";
import getQuizzesSlicer from "./custom-actions/quizzes-actions/GetQuizzesSlicer";
import addQuizeSlicer from "./custom-actions/quizzes-actions/AddNewQuizeSlicer";
import deleteQuizeSlicer from "./custom-actions/quizzes-actions/DeleteQuizeSlicer";
import approveQuizeSlicer from "./custom-actions/quizzes-actions/ApproveQuizeSlicer";
import unApproveQuizeSlicer from "./custom-actions/quizzes-actions/UnApproveQuizeSlicer";
import getAllQuizzesSlicer from "./custom-actions/quizzes-actions/GetAllQuizzesSlicer";
import linkQuizeWithCourseSlicer from "./custom-actions/quizzes-actions/LinkQuizeWithCourseSlicer";
import unLinkQuizSlicer from "./custom-actions/quizzes-actions/UnLinkQuizSlicer";

//attempts slicers
import postAttempGradeSlicer from "./custom-actions/attempts-actions/PostAttemptsGradeSlicer";
import getQuizeAttemptsSlicer from "./custom-actions/attempts-actions/GetAttemptsSlicer";
import getAllCourseAttemptsSLicer from "./custom-actions/attempts-actions/GetAllCourseAttemptsSlicer";
import deleteAttemptSlicer from "./custom-actions/attempts-actions/DeleteAttemptSlicer";

// questions slicers
import addQuestionSlicer from "./custom-actions/Question-actions/AddQuestionSlicer";
import getQuestionsSlicer from "./custom-actions/Question-actions/GetQuestionsSlicer";
import deleteQuestionSlicer from "./custom-actions/Question-actions/DeleteQuestionSlicer";
import addQuestionVideoSlicer from "./custom-actions/Question-actions/UploadQuestionVideoSlicer";

// lessons slicers
import getCourseLessonsSlicer from "./custom-actions/lessons-actions/GetCourseLessons";
import addLessonNameAndDescSlicer from "./custom-actions/lessons-actions/AddLessonNameAndDescSlicer";
import deleteLessonSlicer from "./custom-actions/lessons-actions/DeleteLessonSlicer";
import editLessonSlicer from "./custom-actions/lessons-actions/EditLessonSlicer";
import approveLessonSlicer from "./custom-actions/lessons-actions/ApproveLessonSlicer";
import unApproveLessonSlicer from "./custom-actions/lessons-actions/UnApproveLessonSlicer";
import showLessonSlicer from "./custom-actions/lessons-actions/ShowLessonSlicer";
import addNewLessonSlicer from "./custom-actions/lessons-actions/AddNewLessonSlicer";
import addNewSchuadlesSlicer from "./custom-actions/lessons-actions/lessons-schuadles-actions/NewSchuadlesSlicer";
import getLessonsSchuadlesSlicer from "./custom-actions/lessons-actions/lessons-schuadles-actions/GetLessonsSchuadlesSlicer";
import deleteLessonSchuadleSlicer from "./custom-actions/lessons-actions/lessons-schuadles-actions/DeleteLessonSchuadle";
import editLessonSchuadleSlicer from "./custom-actions/lessons-actions/lessons-schuadles-actions/EditLessonSchuadleSlicer";
import getSingleLessonSchuadleSlicer from "./custom-actions/lessons-actions/lessons-schuadles-actions/GetSingleLessonSchuadleSlicer";
import getLessonExpireSlicer from "./custom-actions/lessons-actions/GetLessonExpireSlicer";
// lessons requirments slicers
import passLessonRequireSlicer from "./custom-actions/lessons-actions/lessons-requirments-actions/PassingLessonRequireSlicer";
import getLessonRequirmentsSlicer from "./custom-actions/lessons-actions/lessons-requirments-actions/GetLessonRequirmentsSlicer";
import deleteLessonRequirmentSlicer from "./custom-actions/lessons-actions/lessons-requirments-actions/DeleteLessonRequirmentSlicer";
import updateLessonRequirmentSlicer from "./custom-actions/lessons-actions/lessons-requirments-actions/UpdateLessonRequirmentSlicer";
import showLessonVideoSlicer from "./custom-actions/lessons-actions/ShowLessonVideoSlicer";

// contacts slicers
import getContactsSlicer from "./custom-actions/contacts-actions/GetContactsSlicer";
import deleteContactSlicer from "./custom-actions/contacts-actions/DeleteContactSlicer";
import readMessageSlicer from "./custom-actions/contacts-actions/ReadMessageSlicer";

// auth slicers
import loginStatus from "./custom-actions/login-action/LoginSlicer";
import logoutSlicer from "./custom-actions/logout-action/LogoutSlicer";

// levels slicer
import getAllLevelsSlicer from "./custom-actions/levels-actions/GetAllLevelsSlicer";
import getAllPermissionsSlicer from "./custom-actions/permissions-actions/GetAllPermissions";

import filterStudentsSlicer from "./custom-actions/students-actions/FilterStudentsSlicer";
import addAssistantSlicer from "./custom-actions/assistants-actions/AddAssistantSlicer";

// invoices slicers
import getInvoicesSlicer from "./custom-actions/invoices-actions/GetInvoicesSlicer";
import getSingleInvoicesSlicer from "./custom-actions/invoices-actions/GetSingleInvoiceSlicer";
import addInvoicesSlicer from "./custom-actions/invoices-actions/AddInvoicesSlicer";
import deleteInvoicesSlicer from "./custom-actions/invoices-actions/DeleteInvoicesSlicer";
import updateInvoicePaymentConfermationSlicer from "./custom-actions/invoices-actions/UpdateInvoicePaymentConfermation";
import getSubscriptionsResultsSlicer from "./custom-actions/invoices-actions/GetCoursesMonthlyResultsSlicer";
import getInvoicesResultsSlicer from "./custom-actions/invoices-actions/GetInvoicesResultsSlicer";
import getMoreDetailsInvoicesSlicer from "./custom-actions/invoices-actions/GetMoreDetailsInvoicesSlicer";


// assistants slicers
import approveAssistantSlicer from "./custom-actions/assistants-actions/ApproveAssistantSlicer";
import unApproveAssistantSlicer from "./custom-actions/assistants-actions/UnApproveAssistantSlicer";


// couponse slicers
import getCouponseSlicer from "./custom-actions/couponse-actions/GetCouponseSlicer";
import deleteCouponeSlicer from "./custom-actions/couponse-actions/DeleteCouponseSlicer";
import addCouponeSlicer from "./custom-actions/couponse-actions/AddCouponseSlicer";


import editAssistantSlicer from "./custom-actions/assistants-actions/EditAssistantSlicer";
import getAssistantsSlicer from "./custom-actions/assistants-actions/GetAssistantsSlicer";

//sessions slicers
import getAllSessionsSlicer from "./custom-actions/sessions-actions/GetAllSessionsSlicer";
import editSessionSlicer from "./custom-actions/sessions-actions/EditSessionSlicer";
import addNewSessionSlicer from "./custom-actions/sessions-actions/AddSessionSlicer";
import deleteSessionSlicer from "./custom-actions/sessions-actions/DeleteSessionSlicer";
import getSingleSessionSlicer from "./custom-actions/sessions-actions/ShowSingleSessionSlicer";

// offline exams slicers
import getSessionExamsSlicer from "./custom-actions/offline-exams-actions/GetSessionExamsSlicer";
import addSessionExamSlicer from "./custom-actions/offline-exams-actions/AddSessionExamSlicer";
import deleteSessionExamSlicer from "./custom-actions/offline-exams-actions/DeleteSessionExamSlicer";

// notfications slicers
import getNotficationSlicer from "./custom-actions/notfications-slicer/GetNotficationSlicer";
import readAllNotficationsSlicer from "./custom-actions/notfications-slicer/ReadAllNotficationsSlicer";
import postMessageToStudentSlicer from "./custom-actions/notfications-slicer/SendNotficationSlicer";

// general settings slicers
import getGeneralSettingsSlicer from "./custom-actions/platform-setting-actions/GetGeneralSettingsSlicel";
import postGeneralSettingsSlicer from "./custom-actions/platform-setting-actions/PostGeneralSettingsSlicer";

// ui settings
import addNewUiSliderSlicer from "./custom-actions/ui-settings-actions/AddUiSliderSlicer";
import getSlidersSliders from "./custom-actions/ui-settings-actions/GetUiSlidersDataSlicer";
import deleteSliderSlicer from "./custom-actions/ui-settings-actions/DeleteSliderSlicer";
import updateSliderSlicer from "./custom-actions/ui-settings-actions/UpdateSliderSlicer";

// activation codes slicers
import addActivationCodeSlicer from "./custom-actions/code-bulcks-actions/AddCodeSlicer";
import getCodesSlicer from "./custom-actions/code-bulcks-actions/GetCodesSlicer";
import deleteActivationCodeSlicer from "./custom-actions/code-bulcks-actions/DeleteCodesSlicer";

// roles slicers
import addRoleSlicer from "./custom-actions/roles-actions/AddRoleSlicer";
import getRolesSlicer from "./custom-actions/roles-actions/GetRolesSlicer";
import deleteRoleSlicer from "./custom-actions/roles-actions/DeleteRoleSlicer";
import updateRoleSlicer from "./custom-actions/roles-actions/UpdateRoleSlicer";
import getSingleRoleSlicer from "./custom-actions/roles-actions/GetSingleRoleSlicer";

// payments slicers
import getPaymentsSlicer from "./custom-actions/payments-actions/GetPaymentsSlicer";
import deletePaymentSlicer from "./custom-actions/payments-actions/DeletePaymentSlicer";
import updatePaymentSlicer from "./custom-actions/payments-actions/UpdatePaymentSlicer";
import getSinglePaymentSlicer from "./custom-actions/payments-actions/GetSinglePaymnetSlicer";
import addPaymentSlicer from "./custom-actions/payments-actions/AddPaymentSlicer";
import postPaymentSettingsSlicer from "./custom-actions/payments-actions/PostPaymentsSettingsSlicer";

// contacts settings slicers
import getContactsSettingsSlicer from "./custom-actions/contact-settings-actions/GetContactsSettingsSlicer";
import addContactSettingsSlicer from "./custom-actions/contact-settings-actions/AddContactSettingsSlicer";

//chapters
import getChaptersSlicer from "./custom-actions/chapters/GetChaptersSlicer";
import addChapterSlicer from "./custom-actions/chapters/AddChapterSlicer";
import getSingleChapterSlicer from "./custom-actions/chapters/GetSingleChapterSlicer";
import updateChapterSlicer from "./custom-actions/chapters/UpdateChapterSlicer";
import deleteChapterSlicer from "./custom-actions/chapters/DeleteChapterSlicer";
import updateUserPermissionsSlicer from "./custom-actions/users-actions/UpdateUserPermissionsSlicer";

//reviews
import getReviewsSlicer from "./custom-actions/reviews/GetReviewsSlicer";
import updateReviewsSlicer from "./custom-actions/reviews/UpdateReviewsSlicer";

//subscriptions
import getSubscriptionSlicer from "./custom-actions/subscription-actions/GetSubscriptionsSlicer";
import addSubscriptionSlicer from "./custom-actions/subscription-actions/AddSubscriptionSlicer";
import deleteSubscriptionSlicer from "./custom-actions/subscription-actions/DeleteSubscriptionSlicer";
import getSingleSubscriptionSlicer from "./custom-actions/subscription-actions/GetSingleSubscriptionSlicer";
import updateSubscripePaymentsStatus from "./custom-actions/subscription-actions/UpdateSubscripePaymentsStatus";

//settings
import getBasicSettingsSlicer from "./custom-actions/basic-settings/GetBasicSettingsSlicer";
import postBasicSettingsSlicer from "./custom-actions/basic-settings/PostBasicSettingsSlicer";

//languages
import addNewLanguageSlicer from "./custom-actions/language/AddNewLanguageSlicer";
import deleteLanguageSlicer from "./custom-actions/language/DeleteLanguageSlicer";
import getLanguageSlicer from "./custom-actions/language/GetLanguagesSlicer";
import getSingleLanguageSlicer from "./custom-actions/language/GetSingleLanguageSlicer";
import makeLangDefaultSlicer from "./custom-actions/language/MakeLangDefaultSlicer";
import newTranslateTextSlicer from "./custom-actions/language/NewTranslateTextSlicer";
import translateTextSlicer from "./custom-actions/language/TranslateTextSlicer";
import getLanguageSettingsSlicer from "./custom-actions/language/GetLanguageSettingsSlicer";
import switchLanguageSlicer from "./custom-actions/language/SwitchLanguageSlicer";

//attached
import getAttachedSlicer from "./custom-actions/attached-actions/GetAttachedSlicer";
import showAttachedSlicer from "./custom-actions/attached-actions/ShowAttachedSlicer";
import deleteAttachedSlicer from "./custom-actions/attached-actions/DeleteAttachedSlicer";

//earnings
import getEarningsMonthlySlicer from "./custom-actions/earnings-actions/GetEarningsMonthlySlicer";
import getTeachersEarningsMonthlySlicer from "./custom-actions/earnings-actions/GetTeachersEarningsMonthlySlicer";
import getSalesCoursesSlicer from "./custom-actions/earnings-actions/GetSalesCoursesSlicer";
import getSalesTeachersSlicer from "./custom-actions/earnings-actions/GetSalesTeachersSlicer";


export const myStore = configureStore ({
    reducer : {
        //home
        getHomeDataSlicer,
        // login 
        loginStatus,
        logoutSlicer,
        
        // students slicers
        getAllStudents,
        addStudentSlicer,
        editStudentSlicer,
        deleteStudentSlicer,
        getSingleStudentSlicer,
        filterStudentsSlicer,
        unApproveStudentSlicer,
        approveStudentSlicer,

        // courses slicers
        getAllCoursesSlicer,
        addNewCourseSlicer,
        deleteCourseSlicer,
        getSingleCourseSlicer,
        editCourseSlicer,
        approveCourseSlicer,
        unApproveCourseSlicer,
        filterCoursesSlicer,
        updateCourseImageSlicer,
        addNewExtintionSlicer,
        getCourseExcutionsSlicer,
        deleteCourseExcutionSlicer,
        getSingleExcutionSlicer,
        updateCourseExecutionSlicer,

        //lessons slicers
        getCourseLessonsSlicer,
        addLessonNameAndDescSlicer,
        deleteLessonSlicer,
        editLessonSlicer,
        approveLessonSlicer,
        unApproveLessonSlicer,
        showLessonSlicer,
        addNewLessonSlicer,
        addNewSchuadlesSlicer,
        getLessonsSchuadlesSlicer,
        deleteLessonSchuadleSlicer,
        editLessonSchuadleSlicer,
        getSingleLessonSchuadleSlicer,
        getLessonExpireSlicer,
        // lessons requirments slicers
        passLessonRequireSlicer,
        getLessonRequirmentsSlicer,
        deleteLessonRequirmentSlicer,
        updateLessonRequirmentSlicer,
        showLessonVideoSlicer,
        
        // users slicers
        fetchUsersSlicer,
        addNewUserSLicer,
        editUserSlicer,
        getSingleUserSlicer,
        editUserImageSlicer,
        changeUserPasswordSlicer,
        filterInstructorsSlicer,
        approveTeacherSlicer,
        unApproveTeacherSlicer,
        deleteUserSlicer,

        //levels slicers
        getAllLevelsSlicer,
        
        //invoices slicers
        getInvoicesSlicer,
        addInvoicesSlicer,
        deleteInvoicesSlicer,
        getSubscriptionsResultsSlicer,
        getSingleInvoicesSlicer,
        updateInvoicePaymentConfermationSlicer,
        getInvoicesResultsSlicer,
        getMoreDetailsInvoicesSlicer,

        //permissions slicers
        getAllPermissionsSlicer,

        //categories slicers
        getAllCategoriesSlicer,
        getSingleCategorySlicer,
        deleteCategorySlicer,
        addCategorySlicer,
        updateCategorySlicer,

        //contacts slicers
        getContactsSlicer,
        deleteContactSlicer,
        readMessageSlicer,

        //question slicers
        addQuestionSlicer,
        getQuestionsSlicer,
        deleteQuestionSlicer,
        addQuestionVideoSlicer,

        //quizzes slicers
        getAllQuizzesSlicer,
        getQuizzesSlicer,
        addQuizeSlicer,
        deleteQuizeSlicer,
        approveQuizeSlicer,
        unApproveQuizeSlicer,
        linkQuizeWithCourseSlicer,
        unLinkQuizSlicer,

        //assistants slicers 
        getAssistantsSlicer,
        addAssistantSlicer,
        approveAssistantSlicer,
        unApproveAssistantSlicer,
        editAssistantSlicer,

        // attempts slicers
        postAttempGradeSlicer,
        getQuizeAttemptsSlicer,
        getQuizeSingleAttemptsSlicer,
        getAllCourseAttemptsSLicer,
        deleteAttemptSlicer,

        // sessions slicer
        getAllSessionsSlicer,
        editSessionSlicer,
        addNewSessionSlicer,
        deleteSessionSlicer,
        getSingleSessionSlicer,

        // offline exams slicers
        getSessionExamsSlicer,
        addSessionExamSlicer,
        deleteSessionExamSlicer,

        // notfications slicers
        getNotficationSlicer,
        readAllNotficationsSlicer,
        postMessageToStudentSlicer,

        // settings slicers
        getGeneralSettingsSlicer,
        postGeneralSettingsSlicer,

        // ui settings slicers
        addNewUiSliderSlicer,
        getSlidersSliders,
        deleteSliderSlicer,
        updateSliderSlicer,

        // activation codes slicers
        addActivationCodeSlicer,
        getCodesSlicer,
        deleteActivationCodeSlicer,

        // roles slicers
        addRoleSlicer,
        getRolesSlicer,
        deleteRoleSlicer,
        updateRoleSlicer,
        getSingleRoleSlicer,

        // couponse slicers
        getCouponseSlicer,
        deleteCouponeSlicer,
        addCouponeSlicer,

        // payments slicers
        getPaymentsSlicer,
        deletePaymentSlicer,
        updatePaymentSlicer,
        getSinglePaymentSlicer,
        addPaymentSlicer,
        postPaymentSettingsSlicer,

        // contacts slicers
        getContactsSettingsSlicer,
        addContactSettingsSlicer,

        //chapters
        getChaptersSlicer,
        addChapterSlicer,
        getSingleChapterSlicer,
        updateChapterSlicer,
        deleteChapterSlicer,
        
        updateUserPermissionsSlicer,

        //reviews
        getReviewsSlicer,
        updateReviewsSlicer,

        //subscriptions
        getSubscriptionSlicer,
        addSubscriptionSlicer,
        deleteSubscriptionSlicer,
        getSingleSubscriptionSlicer,
        updateSubscripePaymentsStatus,

        //basic settings
        getBasicSettingsSlicer,
        postBasicSettingsSlicer,

        //languages
        addNewLanguageSlicer,
        deleteLanguageSlicer,
        getLanguageSlicer,
        getSingleLanguageSlicer,
        makeLangDefaultSlicer,
        newTranslateTextSlicer,
        translateTextSlicer,
        getLanguageSettingsSlicer,
        switchLanguageSlicer,

        //attached
        getAttachedSlicer,
        showAttachedSlicer,
        deleteAttachedSlicer,

        //earnings
        getEarningsMonthlySlicer,
        getTeachersEarningsMonthlySlicer,
        getSalesCoursesSlicer,
        getSalesTeachersSlicer,
    }
})