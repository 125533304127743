import { Modal , Button } from "react-bootstrap";
import { useState , useEffect, useContext } from "react";
import { useSelector , useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ContextApi from "../../../store/ContextApi";
import { passLessonRequireFunc } from "../../../store/custom-actions/lessons-actions/lessons-requirments-actions/PassingLessonRequireSlicer";
import correctIcon from "../../../media/images/checklist.png";
import videoIcon from "../../../media/images/video-camera.png";
import quizIcon from "../../../media/images/quiz.png";
import fileIcon from "../../../media/images/google-docs.png";
import pdfIcon from "../../../media/images/file.png";
import rich_textIcon from "../../../media/images/files.png";
import { useTranslation } from "react-i18next";
import { getChaptersFunc } from "../../../store/custom-actions/chapters/GetChaptersSlicer";
import { TreeSelect } from "antd";
import GetCourseLessons, { fetchCourseLessonsFunc } from "../../../store/custom-actions/lessons-actions/GetCourseLessons";


const convertAllChildrenKey = (data) => {
    if (!Array.isArray(data)) return [];
    return data.map((item) => {
      const { name, id, all_children, ...rest } = item;
      return {
        ...rest,
        title: name,
        value: id,
        children: convertAllChildrenKey(all_children),
      };
    });
  };
  
  const TreeSelectExam = ({treeData , setNewIdFunc}) => {
    const [value, setValue] = useState();
    const onChange = (newValue) => {
      console.log(newValue);
      setNewIdFunc(newValue)
      setValue(newValue);
    };
    const modifiedTreeData = convertAllChildrenKey(treeData);
  
    return (
      <TreeSelect
        style={{
          width: '100%',
          height: '40px',
        }}
        value={value}
        dropdownStyle={{
          maxHeight: 400,
          overflow: 'auto',
        }}
        treeData={modifiedTreeData}
        placeholder="Please select"
        treeDefaultExpandAll
        onChange={onChange}
        treeDataSimpleMode={{
          id: 'value',
          pId: 'parentId',
          rootPId: null,
        }}
      />
    );
  };
  


function PassingLessonRequireForm(props) {
    const {t} = useTranslation();
    const {
        handleSubmit,
        formState: { errors },
    } = useForm();
    const {lesson_id  , lessons , reftchData} = props;
    const {loading} = useSelector(state => state.passLessonRequireSlicer);
    const dispatch = useDispatch();
    const contextApi = useContext(ContextApi);
    const LinkApi = `${contextApi.dashboard_URL_API}/lessons/${lesson_id}/lesson-requirements`;
    const [chooseLesson , setChooseLesson] = useState();
    const [lessonWhichChoosed , setLessonWhichChoosed] = useState("");
    const [lessonWhichChoosedType , setLessonWhichChoosedType] = useState("");
    const [lessonsList , setLessonsList] = useState([]);
    const [lessonId , setLessonId] = useState();
    const [minPassingGrade , setMinPassingGrade] = useState();
    const [chapterId , setChapterId] = useState(null);
    const [chaptersList , setChaptersList] = useState([]);
    const { loading: getChaptersLoading, data:cahptersData } = useSelector(
        (state) => state.getChaptersSlicer
    );
    const { loadingCourseLessons: getLessonsLoading} = useSelector(
        (state) => state.getCourseLessonsSlicer
    );
    const handleSubmitRequirment = () => {
        if(lesson_id && lessonId) {
                dispatch(passLessonRequireFunc({lessonsApi : LinkApi , requirementsData : 
                    {...(minPassingGrade && { min_passing_grade : minPassingGrade}), required_lesson_id : lessonId}}))
                .then(result => {
                    if(result.payload.success === true) {
                        props.onHide();
                        setLessonId("");
                        setMinPassingGrade("");
                        reftchData();
                        setLessonsList([]);
                        setChapterId(null)
                    }
                })
            }
    }
    useEffect(() => {
        if(lessons?.length >= 1) {
            if(!cahptersData) {
                const getChapters = async () => {
                    dispatch(getChaptersFunc(`${contextApi.dashboard_URL_API}/courses/${lessons[0]?.course_id}/chapters`));
                };
                getChapters();
            }else {
                setChaptersList(cahptersData);
            }
        }
    },[lessons, cahptersData])
    useEffect(() => {
        if(chapterId) {
            const lessonsApi = `${contextApi.dashboard_URL_API}/chapters/${chapterId}/lessons`;
            const getLessons = () => {
                dispatch(fetchCourseLessonsFunc(lessonsApi))
                .then((result) => {
                    const filtredLessons = result?.payload?.lessons?.filter(ls => ls.id !== lesson_id);
                    setLessonsList(filtredLessons)
                    // setLessonsList(result?.payload?.lessons);
                })
            }
            getLessons()
        }
    },[chapterId , lesson_id])
    const handleSetChapterId = (id) => {
        setChapterId(id)
    }
    const checkLessonType = (lessonType) => {
        if(lessonType) {
            switch(lessonType) {
                case "video" :
                return <img src={videoIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "quiz" :
                return <img src={quizIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "file" :
                return <img src={fileIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "pdf" :
                return <img src={pdfIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "rich_text" :
                return <img src={rich_textIcon} alt="icon" className="relative mr-1 w-[20px]" />
                default :
                return <img src={videoIcon} alt="icon" className="relative mr-1 w-[20px]" />
            }
        }
    }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="relative flex items-center">
            <img src={correctIcon} alt="corect icon" className="relative mr-2 w-[16px]" />
            <h4 className="m-0">{t("New Requirment")}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
            <div className="basic-form">
                <div className="form-group mb-3 col-sm-12">
                  {
                      getChaptersLoading ? (
                          <div className="form-group flex items-center">
                              <p>{t("loading")}...</p>
                          </div>
                      ) : cahptersData &&
                          chaptersList &&
                          chaptersList.length <= 0 ? (
                          <div className="form-group flex items-center">
                              <p>{t("No data available")}</p>
                          </div>
                      ) : (
                          <div
                              className={`form-group mb-3 col-sm-12`}
                          >
                          <label>{t("Choose Chapter")}</label>
                            <TreeSelectExam treeData={chaptersList} 
                            setNewIdFunc = {handleSetChapterId}
                            />
                          </div>
                      )
                  }
                </div>
                {
                    chapterId
                    &&
                    (
                        getLessonsLoading
                        ?
                        <div className="form-group flex items-center">
                            <p>{t("loading")}...</p>
                        </div>
                        :
                        (
                            lessonsList?.length >= 1
                            ?
                            <form 
                            className="passing-lesson-form" 
                            id="passing-lesson-form"
                            onSubmit={handleSubmit(() => {
                                handleSubmitRequirment();
                            })
                            }
                            >
                                {
                                    lessonWhichChoosedType === "quiz"
                                    &&
                                    <div className="row">
                                        <div className="form-group mb-3 col-sm-12">
                                            <label className="relative flex items-center">
                                                {t("Min Passing Score")}
                                            </label>
                                            {errors.min_passing_grade?.message && (
                                                <p className="text-red-600">{errors.min_passing_grade?.message}</p>
                                            )}
                                            <input
                                                required
                                                type="number"
                                                min={1}
                                                className="form-control"
                                                onChange={(e) => setMinPassingGrade(e.target.value)}
                                        />
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="form-group mb-3 col-sm-12">
                                        <label>{t("Choose Lesson")}</label>
                                        {errors.type?.message && (
                                        <p className="text-red-600">
                                        {errors.type?.message}
                                        </p>
                                        )}
                                        <span 
                                            className="relative flex justify-between items-center px-2 w-full h-[45px] rounded-md border cursor-pointer bg-white"
                                            onClick={()=>setChooseLesson(!chooseLesson)}
                                        >
                                            <div className="relative flex items-center">
                                                {lessonWhichChoosed}
                                            </div>
                                            <i className={`fa-solid fa-circle-chevron-left transition-all duration-500 text-[20px] text-[#075985] ${chooseLesson ? "rotate-[-90deg] opacity-100" : "rotate-[90deg] opacity-70"} `}></i>
                                        </span>
                                    </div>
                                    <div className={`instructors-list overflow-y-auto transition-all duration-500 w-[98%] cursor-pointer ${chooseLesson ? "h-[150px]" : "h-0"}`}>
                                    { lessonsList &&
                                        lessonsList.map((lesson , index) =>{
                                        return (
                                            <div key={index} className="input-div relative flex items-center my-1 p-2 pl-0">
                                            <input
                                            form="new-course-form"
                                            id={lesson.id} 
                                            type="radio"
                                            name="lesson-id" 
                                            value={lesson.id}
                                            className="opacity-0"
                                            data-name={lesson.name}
                                            onChange={(e)=>{
                                                setLessonWhichChoosedType(lesson.type);
                                                setLessonWhichChoosed(e.target.dataset.name);
                                                setChooseLesson(false);
                                                setLessonId(e.target.value);
                                            }}
                                            />
                                            <label htmlFor={lesson.id} className="cursor-pointer mr-2 flex items-center">
                                                {checkLessonType(lesson.type)}
                                                {lesson.name}
                                            </label>
                                            </div>
                                        )
                                        })
                                    }
                                    </div>
                                </div>
                            </form>
                            :
                            <div className="form-group flex items-center">
                                <p>{t("No data available")}</p>
                            </div>
                        )
                    )
                }
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{
            props.onHide();
          }}>{t("Close")}</Button>
        <Button type="submit" form="passing-lesson-form" className={`btn btn-primary flex items-center justify-center ${loading ? "pointer-events-none" : ""}`}>
            {!loading ? (
            t("Submit")
            ) : (
            <div className="relative flex justify-center items-center">
                {t("loading")}...
                <div className="loading-spin-data w-[15px] h-[15px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-1"></div>
            </div>
            )}
        </Button>
        </Modal.Footer>
      </Modal>
    );
  }

export default PassingLessonRequireForm;