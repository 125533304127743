import { useContext, useEffect, useState } from "react";
import ContextApi from "../../../store/ContextApi";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentsFunc } from "../../../store/custom-actions/payments-actions/GetPaymentsSlicer";
import Swal from "sweetalert2";
import { deletePaymentFunc } from "../../../store/custom-actions/payments-actions/DeletePaymentSlicer";
import swal from "sweetalert";
import { Spinner } from "react-bootstrap";
import faildImg from "../../../media/images/planet.png";
import PaymentCard from "./PaymentCard";
import "./payment-page.css";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import PageTitle from "../../layouts/PageTitle";
import { postPaymentSettingsFunc } from "../../../store/custom-actions/payments-actions/PostPaymentsSettingsSlicer";
import { useTranslation } from "react-i18next";

export default function PaymentsPage(){
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const payments_API_URL = `${contextApi.dashboard_URL_API}/payment-settings`;
    const dispatch = useDispatch();
    const {loading:getPaymentsLoading , data:paymentsData} = useSelector(
        state => state.getPaymentsSlicer
    )
    const {loading:postSettingsLoading} = useSelector(
        state => state.postPaymentSettingsSlicer
    )
    const [paymentsGateway , setPaymentsGateway] = useState();

    const getPayments = () => {
        try {
            dispatch(getPaymentsFunc(payments_API_URL))
        }
        catch (error) {
            Swal.fire({
                title: t("Oops!"),
                text: error,
                icon: "warning"
            });
        }
    }
    useEffect(() => {
        getPayments()
    },[])
    useEffect(() => {
        paymentsData && setPaymentsGateway(paymentsData);
    },[paymentsData])

    const {register , setValue , formState : {errors} , handleSubmit} = useForm();
    const handleSubmitSettings = async () => {
        if(Object.keys(paymentsGateway).length >= 1) {
            const formData = new FormData();
            for(const [key , value] of Object.entries(paymentsGateway)) {
                formData.append(key , value)
            }
            dispatch(postPaymentSettingsFunc({
                api : payments_API_URL , 
                data : formData
            }))
            .then(result => {
                if(result.payload.success === true) {
                    // getSettings();
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }else {
            toast.error(t(`Can Not Submit Empty Data!`), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        }
    }

    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Payments Settings"} />
        <div className="relative flex items-center justify-between my-3">
                <div className="relative flex items-center">
                    {/* <button class="rounded-lg relative w-36 h-10 cursor-pointer flex items-center border border-orange-500 bg-orange-500 group hover:bg-orange-500 active:bg-orange-500 active:border-orange-500 overflow-hidden text-white"
                        onClick={() => window.history.back()}
                    > */}
                    <button 
                        form="payments-gateway-from"
                        className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-orange-400 duration-300 mx-1
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        onClick={() => window.history.back()}
                        >
                        {t("Back")}
                        {/* <span class="text-white font-semibold ml-8 transform group-hover:translate-x-20 transition-all duration-300">{t("Back")}</span> */}
                        {/* <span class="absolute right-0 h-full w-10 rounded-lg bg-orange-500 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
                            <i className="fa-solid fa-arrow-left text-white"></i>
                        </span> */}
                    </button>
                    <button 
                        type="submit"
                        form="payments-gateway-from"
                        className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-orange-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                postSettingsLoading
                                ?
                                <>{("Please Wait")}...</>
                                :
                                <>
                                    {t("Submit")}
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
        </div>
            {
                getPaymentsLoading
                ?
                (
                    <>
                        <div className="row d-flex justify-content-center gap-2">
                            <Spinner animation="grow" variant="warning" />
                            <Spinner animation="grow" variant="warning" />
                            <Spinner animation="grow" variant="warning" />
                            <Spinner animation="grow" variant="warning" />
                        </div>
                    </>
                )
                :
                (
                
                    paymentsData
                    ?
                    (
                        paymentsGateway
                        &&
                        Object.keys(paymentsGateway).length >= 1
                        ?
                        // <div className="relative flex flex-wrap">
                        // {
                        //     paymentsList.map((payment , index) => {
                        //         return (
                        //             <PaymentCard
                        //                 key={index}
                        //                 payment={payment}
                        //                 paymentTitle={payment.title}
                        //                 status = {payment.active}
                        //             />
                        //         )
                        //     })
                        // }
                        // </div>
                        <div className="row">
                        <div className="col-xl-12 col-xxl-12">
                            <div className="card p-3">
                                <form
                                    id="payments-gateway-from"
                                    onSubmit={handleSubmit((data) => {
                                        handleSubmitSettings()
                                    })}
                                >
                                    <div className="row">
                                        {
                                        Object.keys(paymentsGateway).length < 1
                                        &&
                                        <p className="my-2 text-center">Add Product Attributes ({t("At Least one Attribute")}..)</p>
                                        }
                                        {
                                        Object.keys(paymentsGateway).length >= 1
                                        &&
                                        Object.keys(paymentsGateway).map((key , index) => {
                                            return (
                                            <div className="row flex items-center col-sm-12" key={index}>
                                                {/* <div className="form-group mb-3 col-sm-2 col-md-3"> */}
                                                    {/* <input
                                                    type="text"
                                                    className="form-control key-input"
                                                    value={key}
                                                    disabled
                                                    /> */}
                                                {/* </div> */}
                                                <div className="form-group mb-3 col-sm-12">
                                                    <label >
                                                        {key.replace(/_/g , " ")}
                                                    </label>
                                                    {
                                                        key !== "paymob_card_gateway"
                                                        &&
                                                        key !== "paymob_wallet_gateway"
                                                        &&
                                                        // key !== "cash_on_delivery"
                                                        // &&
                                                        key !== "send_receipt"
                                                        &&
                                                        key !== "cash_on_site"
                                                        ?
                                                        <input
                                                        type="text"
                                                        className={`form-control`}
                                                        defaultValue={paymentsGateway[key]}
                                                        // disabled
                                                        onChange={(e) => {
                                                            setPaymentsGateway((prevObject) => ({
                                                                ...prevObject,
                                                                [key]: e.target.value,
                                                            }));
                                                        }}
                                                        />
                                                        :
                                                        <input
                                                        type="checkbox"
                                                        id="customCheckBox5"
                                                        className="form-check-input scale-110 cursor-pointer ml-2"
                                                        checked = {parseInt(paymentsGateway[key]) === 1 ? true : false}
                                                        onChange={(e) => {
                                                            console.log(e.target.checked)
                                                            setPaymentsGateway((prevObject) => ({
                                                                ...prevObject,
                                                                [key]: e.target.checked ? 1 : 0,
                                                            }));
                                                        }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            )
                                        })
                                        }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                        :
                        <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                            <img src={faildImg} alt="faild img" className='w-[150px] mb-3'/>
                            <p>{t("No data available")}
                            </p>
                        </div>
                        
                    )
                    :
                    <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                        <img src={faildImg} alt="faild img" className='w-[150px] mb-3'/>
                        <p>{t("No data available")}
                        <span className="text-[#4d5fe3] cursor-pointer"
                        onClick={()=> getPayments()}
                        >
                        {t("Try again")}
                        </span>
                        </p>
                    </div>
                )
            }
        </>
    )
}