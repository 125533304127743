import { useState , useEffect, useContext, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import faildImg from "../../../media/images/planet.png"
import ContextApi from "../../../store/ContextApi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getEarningsMonthlyFunc } from "../../../store/custom-actions/earnings-actions/GetEarningsMonthlySlicer";

function MonthlyEarningsPage() {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const earnings_API_URL = `${contextApi.dashboard_URL_API}/report/subscriptions-monthly`;
    const [earningsList , setEarningsList] = useState([]);
    const dispatch = useDispatch();
    const [monthSearchValue , setMonthSearchValue] = useState("");
    const [filterValue , setFilterValue] = useState("");
    const {loading:getDataLoading , data} = useSelector(
        state => state.getEarningsMonthlySlicer
    )
    const {data:settingsData} = useSelector(
        state => state.getBasicSettingsSlicer
      )
    const getData = async () => {
        try {
            dispatch(getEarningsMonthlyFunc(earnings_API_URL))
        }
        catch (error){
            alert(error)
        }
    }
    useEffect(() => {
      getData()
    },[])
    useEffect(() => {
        data && setEarningsList(data)
        data && (jobData.current = data.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ));
    },[data])
    const sort = 20;
    let paggination = Array(Math.ceil(earningsList.length / sort))
      .fill()
      .map((_, i) => i + 1);
  
    const activePag = useRef(0);
    const jobData = useRef(
      earningsList.slice(
        activePag.current * sort,
        (activePag.current + 1) * sort
      )
    );
    const onClick = (i) => {
      activePag.current = i;
      jobData.current = earningsList.slice(
        activePag.current * sort,
        (activePag.current + 1) * sort
      );
    };
    // useEffect(() => {
    //     let api = `${earnings_API_URL}${yearSearchValue === "" ? (monthSearchValue !== "" ? `?month=${monthSearchValue}` : ''): (monthSearchValue !== "" ? `?year=${yearSearchValue}&month=${monthSearchValue}` : `?year=${yearSearchValue}`)
    //     }`;
    //     let searchTimeout;
    //     let getData = async ()=>{
    //       if(yearSearchValue !== "" || monthSearchValue !== "") {
    //         clearTimeout(searchTimeout);
    //         searchTimeout = setTimeout(()=>{
    //           dispatch(getEarningsMonthlyFunc(api))
    //         },1000)
    //       }else {
    //         dispatch(getEarningsMonthlyFunc(api))
    //       }
    //     }
    //     getData()
    //     return () => clearTimeout(searchTimeout)
    //   },[monthSearchValue , yearSearchValue])

    useEffect(() => {
      if(filterValue !== "") {
        const stringDate = new Date(filterValue);
        const month = stringDate.getMonth()+1;
        const year = stringDate.getFullYear();
        const api = `${earnings_API_URL}${year ? `?year=${year}&month=${month}` : ""}`
        let searchTimeout;
        let getData = async ()=>{
          if(year) {
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(()=>{
              dispatch(getEarningsMonthlyFunc(api))
            },1000)
          }else {
            dispatch(getEarningsMonthlyFunc(api))
          }
        }
        getData()
        return () => clearTimeout(searchTimeout)
      }else {
        dispatch(getEarningsMonthlyFunc(earnings_API_URL))
      }
    },[filterValue])



    return (
        <>
            <PageTitle activeMenu="Monthly Earnings" motherMenu="Dashboard" />
            <div className="card">
                <div className="card-header flex justify-between items-center">
                    <h4 className="card-title">{t("Earnings List")}</h4>
                    <div className="relative add-and-search flex items-center">
                    {/* <label className="mx-2">Filter With Month</label>
                    <div className="input-group search-area w-auto mr-2">
                        <select 
                        className="form-control"
                        onChange={(e) => {
                            setMonthSearchValue(e.target.value);
                        }}
                        >
                            <option value={""}>All</option>
                            <option value={"1"}>1</option>
                            <option value={"2"}>2</option>
                            <option value={"3"}>3</option>
                            <option value={"4"}>4</option>
                            <option value={"5"}>5</option>
                            <option value={"6"}>6</option>
                            <option value={"7"}>7</option>
                            <option value={"8"}>8</option>
                            <option value={"9"}>9</option>
                            <option value={"10"}>10</option>
                            <option value={"11"}>11</option>
                            <option value={"12"}>12</option>
                        </select>
                    </div> */}
                    <div className="input-group search-area w-auto mr-2">
                        <span className="input-group-text"
                        >
                            <Link to={"#"}>
                            <svg 
                                width="24" 
                                height="24" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                xmlns="http://www.w3.org/2000/svg">
                              <path 
                                d="M19 4H18V2H16V4H8V2H6V4H5C3.9 4 3 4.9 3 6V20C3 21.1 3.9 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4ZM19 20H5V9H19V20ZM19 7H5V6H19V7Z" 
                                fill="var(--primary)" />
                            </svg>
                            </Link>
                        </span>
                        <input
                            type="month"
                            className="form-control"
                            // placeholder={t("Search With Year...")}
                            onChange={(e) => {
                                setFilterValue(e.target.value);
                                console.log(e.target.value)
                            }}
                            value={filterValue}
                        />
                    </div>
                    <Link 
                      to="#"
                      className="btn btn-primary btn-sm"
                      onClick={() => setFilterValue("")}
                      >
                        {t("Reset")}
                        {/* <i className="fa-solid fa-plus ml-1"></i> */}
                    </Link>
                    </div>
                </div>
                <div className="card-body">
                    <div className="content relative w-full">
                        {
                            getDataLoading
                            ?
                            (
                                <>
                                    <div className="row d-flex justify-content-center gap-2">
                                        <Spinner animation="grow" variant="warning" />
                                        <Spinner animation="grow" variant="warning" />
                                        <Spinner animation="grow" variant="warning" />
                                        <Spinner animation="grow" variant="warning" />
                                    </div>
                                </>
                            )
                            :
                            (
                                data
                                ?
                                (
                                    <div className="table-responsive">
                                    <div
                                        id="student_wrapper"
                                        className="dataTables_wrapper no-footer"
                                    >
                                        <table
                                        className="table display mb-4 dataTablesCard order-table card-table text-black application "
                                        id="application-tbl1_next"
                                        >
                                        <thead>
                                            <tr>
                                            <th className="">{t("Date")}</th>
                                            <th className="">{t("Earnings")}</th>
                                            </tr>
                                        </thead>
                                            <tbody>
                                                {earningsList &&
                                                earningsList.length > 0 ?
                                                jobData.current.map((item, index) => {
                                                    return (
                                                    <tr key={index}>
                                                        <td className="">
                                                            {item.month}
                                                            -
                                                            {item.year}
                                                        </td>
                                                        <td className="">
                                                            {item.grand_total} {settingsData?.site_global_currency}
                                                        </td>
                                                    </tr>
                                                    );
                                                })
                                                :
                                                <td colSpan={4} className="">
                                                    <p className="m-0 border-none">{t("No Data!")}</p> 
                                                </td>
                                                }
                                            </tbody>
                                        </table>
                                        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                    <div className="dataTables_info">
                                      {t("Showing")} {activePag.current * sort + 1} to{" "}
                                      {earningsList.length > (activePag.current + 1) * sort
                                        ? (activePag.current + 1) * sort
                                        : earningsList.length}{" "}
                                      {t("of")} {earningsList.length} {t("entries")}
                                    </div>
                                    <div
                                      className="dataTables_paginate paging_simple_numbers mb-0"
                                      id="example5_paginate"
                                    >
                                      <Link
                                        className="paginate_button previous disabled"
                                      
                                        to="#"
                                        onClick={() =>
                                          activePag.current > 0 && onClick(activePag.current - 1)
                                        }
                                      >
                                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                      </Link>
                                      <span>
                                        {paggination.map((number, i) => (
                                          <Link
                                            key={i}
                                          
                                            to="#"
                                            className={`paginate_button  ${
                                              activePag.current === i ? "current" : ""
                                            } `}
                                            onClick={() => onClick(i)}
                                          >
                                            {number}
                                          </Link>
                                        ))}
                                      </span>
                                      <Link
                                        className="paginate_button next"
                                      
                                        to="#"
                                        onClick={() =>
                                          activePag.current + 1 < paggination.length &&
                                          onClick(activePag.current + 1)
                                        }
                                      >
                                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                      </Link>
                                    </div>
                                  </div>
                                    </div>
                                    </div>
                                )
                                :
                                <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                                    <img src={faildImg} alt="faild img" className='w-[150px] mb-3'/>
                                    <p>{t("No data available")} 
                                    <span className="text-[#4d5fe3] cursor-pointer"
                                    onClick={()=> getData()}
                                    >
                                    {t("Try again")}
                                    </span>
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default MonthlyEarningsPage;